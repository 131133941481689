import React from 'react';
import cn from 'classnames';

const TextInput = ({ className, label, type, value, onChange }) => {
  return (
    <label>
      {label}
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={cn(className, 'block w-full bg-body border-b-2 border-border outline-none')}
      />
    </label>
  );
};

export default TextInput;
