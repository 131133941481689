import React, { useEffect } from 'react';
import cn from 'classnames';

const SearchInput = ({ openModal, closeModal, isModalOpen }) => {
  useEffect(() => {
    const handleShortcut = (event) => {
      if (event.metaKey && event.key === 'k') {
        event.preventDefault();
        openModal();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        closeModal();
      }
    };

    document.addEventListener('keydown', handleShortcut);

    return () => {
      document.removeEventListener('keydown', handleShortcut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      openModal();
    }
  };

  return (
    <div className={cn('relative mt-4 mx-1', 'hidden lg:block')}>
      <input
        type="search"
        className={cn(
          'w-full px-3.5 py-1 text-sm border-b-2 bg-bg rounded-full',
          'focus:border-accent outline-none cursor-pointer transition-[border-color]',
          {
            'border-border': !isModalOpen,
            'border-accent': isModalOpen,
          },
        )}
        placeholder="Search"
        onClick={openModal}
        readOnly
        tabIndex={1}
        onKeyDown={handleKeyDown}
      />
      <span
        className={cn(
          'absolute top-1.5 right-4 text-xs font-semibold text-light-text',
          'hidden lg:block',
        )}
      >
        ⌘ + K
      </span>
    </div>
  );
};

export default SearchInput;
