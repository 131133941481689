import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

const Backdrop = ({ children, onClick }) => {
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.body.style.overflow = 'hidden';

    if (scrollbarWidth) {
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  return (
    <motion.div
      className={cn(
        'fixed left-0 top-0 z-50 flex lg:items-center justify-center w-full h-full bg-[#0000004d] backdrop-blur-sm',
        'items-end',
      )}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
