function debounce(f, delay) {
  let timerId = 0;

  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(f, delay, ...args);
  };
}

export default debounce;
