import React, { useState, useEffect, useMemo, createContext } from 'react';

export const ThemeContext = createContext();

const defineInitialTheme = () => {
  if (typeof window === 'undefined') {
    return false;
  }

  const selectedTheme = localStorage.getItem('theme');

  if (selectedTheme === 'light') {
    return false;
  } else if (selectedTheme === 'dark') {
    document.body.classList.add('dark');
    return true;
  } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.body.classList.add('dark');
    return true;
  }

  return false;
};

const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(defineInitialTheme());

  useEffect(() => {
    document.body.classList.add('animated');
  }, []);

  const toggleTheme = () => {
    setIsDark((current) => {
      if (current) {
        localStorage.setItem('theme', 'light');
        document.body.classList.remove('dark');

        return false;
      } else {
        localStorage.setItem('theme', 'dark');
        document.body.classList.add('dark');

        return true;
      }
    });
  };

  const value = useMemo(
    () => ({
      isDark,
      toggleTheme,
    }),
    [isDark],
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
