import React, { useContext } from 'react';
import { IoSunnySharp, IoMoonSharp } from 'react-icons/io5';

import { ThemeContext } from '../../context/Theme';

const ICON_CLASS = 'w-5 h-3 z-10 dark:rotate-[360deg] transition-transform duration-700';

const ThemeToggle = () => {
  const { toggleTheme } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className="flex justify-between items-center gap-x-1 w-max p-2 bg-accent rounded-full"
    >
      <IoSunnySharp className={ICON_CLASS} />
      <IoMoonSharp className={ICON_CLASS} />
      <div className="absolute w-5 h-5 bg-bg rounded-full dark:translate-x-6 shadow-sm transition-transform duration-300" />
    </button>
  );
};

export default ThemeToggle;
