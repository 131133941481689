import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import Backdrop from '../Backdrop';

const dropIn = {
  hidden: {
    y: '100vh',
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      type: 'spring',
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const Modal = ({ children, onClose, fullScreen }) => {
  return (
    <Backdrop onClick={onClose}>
      <motion.div
        className={cn('p bg-body rounded-t-xl lg:rounded-b-xl overflow-auto', {
          'w-full lg:w-[800px] h-[90svh] lg:h-[90vh]': fullScreen,
        })}
        onClick={(event) => event.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {children}
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
