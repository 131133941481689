import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import cn from 'classnames';

import { signIn } from '../../restApi/auth';
import useAuth from '../../hooks/useAuth';
import TextInput from '../TextInput';

const SignInForm = ({ closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShakingPassword, setIsShakingPassword] = useState(false);
  const { authenticateUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignIn = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const { accessToken, user } = await signIn({
        email,
        password,
      });

      authenticateUser(accessToken, user);
      closeModal();
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response?.data.message || error.message, { variant: 'error' });
      setIsShakingPassword(true);
      setTimeout(() => setIsShakingPassword(false), 600);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSignIn} className="flex flex-col gap-4 flex-nowrap mt-8">
      <TextInput label="Email" type="email" value={email} onChange={handleEmailChange} />
      <TextInput
        label="Password"
        type="password"
        className={cn({ shake: isShakingPassword })}
        value={password}
        onChange={handlePasswordChange}
      />
      <button
        type="submit"
        disabled={isLoading}
        className={cn('mt-6 p-2 uppercase bg-bg hover:underline', {
          'opacity-50 hover:no-underline': isLoading,
        })}
      >
        Sign In
      </button>
    </form>
  );
};

export default SignInForm;
