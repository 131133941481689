import React from 'react';
import cn from 'classnames';

const Button = ({ children, onClick, className, isLoading, isDisabled }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(className, 'px-1 py-1 font-medium border-b-2 border-underline', {
        'opacity-50': isLoading,
      })}
      disabled={isDisabled || isLoading}
    >
      {children}
    </button>
  );
};

export default Button;
