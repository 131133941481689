import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { VscListFilter } from 'react-icons/vsc';

import SearchInput from '../SearchInput';
import SearchModal from '../SearchModal';
import ArticleNavigation from '../ArticleNavigation';
import { CgSearch } from 'react-icons/cg';
import { BREAK_POINT } from '../../constants';

const Drawer = ({ sections, isNavDisabled }) => {
  const [scrollCoefficient, setScrollCoefficient] = useState('0%');
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleArticleScroll);
    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.removeEventListener('scroll', handleArticleScroll);
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  useEffect(() => {
    setScrollCoefficient('0%');
  }, [sections]);

  const handleArticleScroll = ({ currentTarget }) => {
    const coefficient = window.scrollY / (currentTarget.body.scrollHeight - window.innerHeight);

    setScrollCoefficient(coefficient.toFixed(3));
  };

  const handleBodyClick = ({ target }) => {
    if (window.innerWidth >= BREAK_POINT.lg || target.closest('#open-drawer, #drawer')) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <>
      <div className="fixed top-4 right-8 lg:hidden flex items-center gap-x-4">
        <button
          id="open-drawer"
          type="button"
          className={cn({ hidden: sections.length <= 1 })}
          onClick={() => setIsOpen(true)}
        >
          <VscListFilter size="24" />
        </button>
        <button
          onClick={() => setIsSearchOpen(true)}
          className={cn(
            'flex items-center justify-center w-10 h-10 rounded-full border-b-2 bg-bg',
            { 'border-border': !isSearchOpen, 'border-accent': isSearchOpen },
          )}
        >
          <CgSearch className="mt-0.5 mr-0.5" />
        </button>
      </div>
      <nav
        id="drawer"
        className={cn(
          'lg:sticky shrink-0 flex flex-col w-[173px] h-dvh-fb bg-body lg:bg-transparent',
          'fixed top-0 right-0 shadow lg:shadow-none lg:translate-x-0 transition-transform duration-300 ease-out',
          { 'translate-x-[173px]': !isOpen },
        )}
      >
        <SearchInput
          isModalOpen={isSearchOpen}
          openModal={() => setIsSearchOpen(true)}
          closeModal={() => setIsSearchOpen(false)}
        />
        {sections.length > 1 && (
          <>
            <ArticleNavigation sections={sections} isDisabled={isNavDisabled} />
            {!isNavDisabled && (
              <div
                className="absolute left-[-6px] lg:left-[-2px] top-0 w-1.5 lg:w-0.5 h-full bg-border transition-[transform] ease-linea"
                style={{ transform: `scale(${scrollCoefficient}` }}
              />
            )}
          </>
        )}
      </nav>
      <SearchModal isOpen={isSearchOpen} closeModal={() => setIsSearchOpen(false)} />
    </>
  );
};

export default Drawer;
