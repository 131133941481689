import React, { useContext } from 'react';
import cn from 'classnames';

import { CompendiumContext } from '../../context/Compendium';
import scrollSmoothly from '../../utils/scrollSmoothly';

const ArticleNavigation = ({ sections, isDisabled }) => {
  const { activeHeading } = useContext(CompendiumContext);

  return (
    <div className="grow flex flex-col justify-center overflow-y-hidden">
      <ul className="flex flex-col pt-8 text-sm overflow-y-auto no-scrollbar">
        {sections.map(({ type, id, name }) => (
          <li
            key={id}
            className={cn('pr-2', {
              'mb-6': type === 'title',
              'mb-2': type !== 'title',
              'pl-4': type !== 'subheading',
              'pl-7': type === 'subheading',
              'font-medium': type === 'title',
              'font-light': type === 'subheading',
              'bg-highlight': activeHeading === id && !isDisabled,
              'hover:bg-highlight': isDisabled,
            })}
          >
            {isDisabled ? (
              <span>{name}</span>
            ) : (
              <a href={`#${id}`} onClick={(event) => scrollSmoothly(event, id)}>
                {name}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleNavigation;
