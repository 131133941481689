import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { VscChevronRight } from 'react-icons/vsc';

import CollectionList from '../CollectionList';
import HomeLink from '../HomeLink';
import useAuth from '../../hooks/useAuth';
import ThemeToggle from '../ThemeToggle';

const RootCollectionList = ({
  structure,
  triggerStructure,
  onCollectionClick,
  chosenCollectionPath,
}) => {
  const { isAdmin } = useAuth();

  return (
    <div className="shrink-0 flex flex-col justify-between w-[148px] border-border border-r-2">
      <ul className="flex flex-col overflow-auto">
        {structure.map(({ name, path, collections, isOpen }) => {
          const isPublic = collections.some(({ articles }) =>
            articles.some(({ isPublic }) => isPublic),
          );

          return (
            <li className="shrink-0 flex flex-col mt-2 overflow-hidden" key={name}>
              <div
                className={cn('flex items-center gap-x-1', {
                  'bg-highlight': `${path}root/` === chosenCollectionPath,
                  'opacity-70': !isPublic && !isAdmin,
                })}
              >
                {collections.length > 1 && (
                  <motion.button
                    animate={{ rotate: isOpen ? 90 : 0 }}
                    onClick={() => triggerStructure(path)}
                  >
                    <VscChevronRight />
                  </motion.button>
                )}
                <span
                  className={cn(
                    { 'ml-5': collections.length <= 1 },
                    'grow font-bold cursor-pointer',
                  )}
                  onClick={() => onCollectionClick(`${path}root/`)}
                >
                  {name}
                </span>
              </div>
              <CollectionList
                isOpen={isOpen}
                collections={collections.filter(({ name }) => name !== 'root')}
                onCollectionClick={onCollectionClick}
                chosenCollectionPath={chosenCollectionPath}
              />
            </li>
          );
        })}
      </ul>
      <div className="grid grid-cols-2 justify-between gap-2 p-4 border-t-2 border-border">
        <HomeLink />
        <Link to="/portfolio/">Portfolio</Link>
        <Link to="/compendium/about/">About</Link>
        <ThemeToggle />
      </div>
    </div>
  );
};

export default RootCollectionList;
