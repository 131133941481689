import React from 'react';

import AuthProvider from '../../context/Auth';
import ToastProvider from '../../context/Toast';
import Authentication from '../Authentication';
import ResponsiveProvider from '../../context/Responsive';
import ThemeProvider from '../../context/Theme';

const App = ({ children }) => {
  return (
    <ThemeProvider>
      <ToastProvider>
        <AuthProvider>
          <Authentication />
          <ResponsiveProvider>{children}</ResponsiveProvider>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
