import publicApi from './clients/public';
import privateApi from './clients/private';
import { removeTrailingSlash } from '../utils/regex';

// todo: this one is unused, but duplicated in gatsby-node
export const getCompendiumMetadata = async () => {
  const { data } = await publicApi.get('/compendium/metadata');

  return data;
};

export const getArticleLikes = async (path) => {
  const { data } = await publicApi.get(`${path}/likes`);

  return data;
};

export const postArticleLike = async (path) => {
  const pathWithoutTrailingSlash = removeTrailingSlash(path);
  const { data } = await publicApi.post(`${pathWithoutTrailingSlash}/likes`);

  return data;
};

export const getPdfArticle = async (path) => {
  const pathWithoutTrailingSlash = removeTrailingSlash(path);
  const response = await privateApi.get(pathWithoutTrailingSlash, { responseType: 'blob' });
  const isRedirect = response.config.baseURL + response.config.url !== response.request.responseURL;

  return {
    body: response.data,
    isRedirect,
  };
};

export const getMDArticle = async (path) => {
  const pathWithoutTrailingSlash = removeTrailingSlash(path);
  const response = await privateApi.get(pathWithoutTrailingSlash);
  const isRedirect = response.config.baseURL + response.config.url !== response.request.responseURL;

  return {
    body: response.data,
    isRedirect,
  };
};

export const getImage = async (path) => {
  const { data } = await privateApi.get(path, { responseType: 'blob' });

  return data;
};

export const search = async (query) => {
  const { data } = await privateApi.get(`/compendium/search?q=${encodeURIComponent(query)}`);

  return data;
};
