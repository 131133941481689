import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import { useSnackbar } from 'notistack';
import { CgSearch } from 'react-icons/cg';
import { VscClose } from 'react-icons/vsc';
import cn from 'classnames';

import Modal from '../Modal';
import { search } from '../../restApi/compendium';
import debounce from '../../utils/debounce';

const SearchModal = ({ isOpen, closeModal }) => {
  const [query, setQuery] = useState('');
  const [findings, setFindings] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const groupFindings = (articles) => {
    return articles.reduce((acc, article) => {
      const {
        structure: { rootCollection, collection },
      } = article;

      if (!acc[rootCollection]) {
        acc[rootCollection] = {};
      }

      if (!acc[rootCollection][collection]) {
        acc[rootCollection][collection] = [];
      }

      acc[rootCollection][collection].push(article);

      return acc;
    }, {});
  };

  const highlightFindings = (articles) => {
    return articles.map((article) => ({
      ...article,
      content: article.content
        .replace(/\n+/g, ' • ')
        .split('<em_s>')
        .map((substring, i) => ({ id: i, substring })),
    }));
  };

  // TODO: fix race condition
  const loadFindings = useCallback(
    debounce(async (newQuery) => {
      try {
        const results = await search(newQuery);

        setFindings(groupFindings(highlightFindings(results)));
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Search error has happended', { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    }, 200),
    [],
  );

  const handleChange = (event) => {
    setQuery(event.target.value);

    if (event.target.value.trim().length > 2) {
      setIsLoading(true);
      loadFindings(event.target.value);
    } else {
      setFindings({});
    }
  };

  const handleReset = () => {
    setQuery('');
    setFindings({});
  };

  const getMatchPerArticle = (content) => {
    return content
      .filter(({ id }) => id % 2 === 1)
      .reduce((acc, { substring }) => (substring.length > acc.length ? substring : acc), '');
  };

  return (
    isOpen && (
      <Modal onClose={closeModal} fullScreen>
        <div className="relative mb-6">
          <CgSearch className="absolute top-3 left-3 text-light-text" size="19" />
          <input
            type="text"
            className={cn(
              'w-full px-10 py-2 font-semibold bg-bg border-b-2 border-border rounded-full',
              'focus:border-accent outline-none',
            )}
            placeholder="Search"
            value={query}
            onChange={handleChange}
            ref={inputRef}
          />
          <button
            type="button"
            className="absolute top-1.5 right-3 bottom-1 text-light-text"
            onClick={handleReset}
          >
            <VscClose size="24" />
          </button>
        </div>
        {Object.entries(findings).map(([rootCollection, collections]) => (
          <React.Fragment key={rootCollection}>
            <h2 className="mt-4 px-1 text font-medium border-b-2 border-border">
              {rootCollection}
            </h2>
            {Object.entries(collections).map(([collection, articles]) => (
              <div key={collection} className={cn('flex gap-2 mt-4', 'flex-col lg:flex-row')}>
                <h3 className="lg:basis-24 shrink-0 lg:text-end text-sm font-medium">
                  {collection}
                </h3>
                <div className="grow flex flex-col gap-y-2 pl-2 border-l-2 border-border overflow-hidden">
                  {articles.map((article) => (
                    <Link
                      key={article.path}
                      to={`/compendium/${article.path}`}
                      className="flex items-baseline gap-2 text-nowrap border border-border px-4 py-1 rounded-full hover:no-underline hover:bg-bg"
                      onClick={closeModal}
                      state={{ searchMatch: getMatchPerArticle(article.content) }}
                    >
                      <h4 className="font-semibold">{article.structure.article}</h4>
                      <span className="text-xs text-light-text text-nowrap text-ellipsis overflow-hidden">
                        {article.content.map(({ id, substring }, i) => (
                          <React.Fragment key={id}>
                            {i % 2 === 0 ? (
                              substring
                            ) : (
                              <span className="font-medium text-purple-600">{substring}</span>
                            )}
                          </React.Fragment>
                        ))}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
        {!Object.entries(findings).length && !isLoading && (
          <p className="mt-24 text-sm font-medium text-center text-light-text">
            {query.length > 2 ? 'Nothing is found' : 'Enter your search query'}
          </p>
        )}
      </Modal>
    )
  );
};

export default SearchModal;
